import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
class DataPromos {
}
DataPromos.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CRM } },
        text: i18n.t("breadcrumbs.crm"),
    },
    {
        disabled: true,
        text: i18n.t("breadcrumbs.promocodes"),
    },
];
DataPromos.TableHeaders = [
    {
        text: i18n.t("table_headers.created_at"),
        value: "created_at",
        width: "15%",
    },
    {
        text: i18n.t("promo.code"),
        value: "code",
    },
    {
        text: i18n.t("promo.discount"),
        value: "discount",
    },
    {
        text: i18n.t("promo.discount_euro"),
        value: "discount_euro",
    },
    {
        text: i18n.t("promo.duration"),
        value: "duration",
    },
    {
        text: i18n.t("promo.valid_from"),
        value: "valid_from",
    },
    {
        text: i18n.t("promo.valid_to"),
        value: "valid_to",
    },
    {
        text: i18n.t("promo.is_enabled"),
        value: "is_enabled",
    },
    {
        text: i18n.t("table_headers.action"),
        width: "10%",
        value: "actions",
        sortable: false,
    },
];
export default DataPromos;
